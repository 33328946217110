.App {
  text-align: center;
}

.clickable:hover {
    cursor: pointer;
    text-decoration: none;
}

a {
    color: #F00;
}

a:hover {
    text-decoration: none;
}

.elementToken {
    color: #F00;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.languageButton {
    font-size: calc(11px + ((100vw - 600px) / 250));
    padding: 12px;
}

.languageButton.highlighted {
    font-weight: bold;
    color: #F00;
    border: solid thin #F00;
}


$color-blossom: #1d7484;
$color-fade: #982c61;

$color-bg: #f9f9f9;
$color-bg-alt: #f1f1f1;

$color-text: #4a4a4a;
$font-size-base: 1.8rem;

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$font-family-heading: $font-family-base;

@import "main";
