.nav {
    background-color: #CCCCCC77;
    padding: 16px;
    border-bottom: solid 2px #AAA;
}

.navimg {
    margin-bottom: 0;
}

.nav-button {
    font-size: 16px;
    padding: 8px;
    margin-right: 8px;
    background-color: #CCCCCC;
}

.nav-button.active {
    background-color: #999999;
    color: white;
}

.nav-button.active span {
    color: white;
}

